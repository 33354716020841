import React, { useEffect, useState, lazy, Suspense } from 'react';
import './App.css';
import { AnchorProvider } from "@coral-xyz/anchor";
import { Connection, Keypair } from "@solana/web3.js";
import NodeWallet from "@coral-xyz/anchor/dist/cjs/nodewallet";
import Loader from './components/Loader/Loader';
import { PumpFunSDK } from "./sdk";
import InfoModal from './components/InfoModal/InfoModal';

const Table = lazy(() => import('./components/Table/Table'));

const server = 'https://pump-radar.com/';
const connection = new Connection('https://solana-mainnet.g.alchemy.com/v2/3opi6CnDphaC32zDEBTnvXfa339PSUrV');
const provider = new AnchorProvider(connection, new NodeWallet(new Keypair()), { commitment: "finalized" });
const sdk = new PumpFunSDK(provider);

const App: React.FC = () => {
    const [message, setMessage] = useState("Pump📡");
    const [solTableData, setSolTableData] = useState<any[]>([]);
    const [tonTableData, setTonTableData] = useState<any[]>([]);
    const [tokenCount, setTokenCount] = useState(0);
    const [previousTokenCount, setPreviousTokenCount] = useState(0);
    const [jiggle, setJiggle] = useState(false);
    const [selectedChain, setSelectedChain] = useState<'SOL' | 'TON'>('SOL');
    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    useEffect(() => {
        const refreshData = async () => {
            await fetchTokenCount();
            await fetchTokensData(selectedChain);
        };

        refreshData();
        const interval = setInterval(refreshData, 5000);
        return () => clearInterval(interval);
    }, [selectedChain]);

    const fetchTokensData = async (chain: string) => {
        const response = await fetch(`${server}api/new_tokens?chain=${chain}`);
        const data = await response.json();
        if (chain === 'SOL') {
            setSolTableData(prevData => mergeData(prevData, data));
        } else if (chain === 'TON') {
            setTonTableData(prevData => mergeData(prevData, data));
        }
    };

    const fetchTokenCount = async () => {
        const response = await fetch(`${server}api/num_tokens`);
        const data = await response.json();
        setPreviousTokenCount(tokenCount);
        setTokenCount(data);

        if (previousTokenCount !== tokenCount) {
            setJiggle(true);
            setTimeout(() => setJiggle(false), 5000);
        }
    };

    const mergeData = (prevData: any[], newData: any[]) => {
        const existingIds = new Set(prevData.map(item => item.contract || item.jetton_master));
        var combinedData = [...prevData];

        newData.forEach(item => {
            if (!existingIds.has(item.contract || item.jetton_master)) {
                combinedData.push(item);
            }
        });

        combinedData.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

        console.log(combinedData)
        return combinedData;
    };

    const toggleChain = (chain: 'SOL' | 'TON') => {
        setSelectedChain(chain);
    };

    return (
        <div className="container">
            <button className="how-it-works-button" onClick={() => setShowModal(true)}>How it works</button>
            <main>
                <div className="header">
                    {message}
                    <span className={`token-count ${jiggle ? 'jiggle' : ''}`}>{tokenCount} tracked Tokens</span>
                    <div className="button-group">
                        <button className="chain-button" onClick={() => toggleChain('SOL')}>
                            SOL
                            <img src="https://pump-radar.com/pumpfun.jpg" alt="Solana" />
                        </button>
                        <button className="chain-button" onClick={() => toggleChain('TON')}>
                            TON
                            <img src="https://pump-radar.com/wonton.jpg" alt="TON" />
                        </button>
                    </div>
                </div>

                <Suspense fallback={<Loader />}>
                    {selectedChain === 'SOL' && solTableData.length > 0 ? (
                        <Table tableData={solTableData} chain="SOL" sdk={sdk} />
                    ) : selectedChain === 'TON' && tonTableData.length > 0 ? (
                        <Table tableData={tonTableData} chain="TON" />
                    ) : (
                        <p>Loading</p>
                    )}
                </Suspense>
                <InfoModal show={showModal} onClose={() => setShowModal(false)} />
            </main>

            <footer className="footer">
            <span>
                Follow us on <a href="https://x.com/Pump_Radar">Twitter</a> for latest news.
                Brought to you by <a href="https://x.com/NeuralCrypt">Dr. Stonkton</a> and the <a
                href="https://x.com/WalletMarketCap">Wallet Market Cap Team</a>.
                Copyrights: 2024 Pump-Radar
            </span>
            </footer>
        </div>
    );

};

export default App;
