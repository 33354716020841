import React, {useEffect, useRef} from 'react';
import './InfoModal.css';

interface InfoModalProps {
    show: boolean;
    onClose: () => void;
}

const InfoModal: React.FC<InfoModalProps> = ({show, onClose}) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (show) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [show, onClose]);

    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal" ref={modalRef}>
                <h2>How it works</h2>
                <p>
                    1. Pump📡 is a real-time tracking tool for new pump.fun and wonton.fun tokens.
                </p>
                <p>
                    2. Pump📡 shows you how unique a token is. <br/>
                    2.1 Absolutely unique tokens are marked with ✅. <br/>
                    2.2 Non-unique tokens are marked with ❌. <br/>
                    2.3 The Red and Greed numbers indicates how often
                    2.4 used Website, Twitter, etc.. has been re-used . <br/>
                    2.5 The ideal value is 1, everything above is risky, we want novell tokens.

                </p>
                <p>
                    3. Pump📡 shows you bonding progress in real-time
                </p>

                <p>
                    4. Pump📡 shows you token age in Minutes/Hours/Days
                </p>
                <p>
                    5. Pump📡 lets you buy in 1-click on Wonton.fun and Pump.fun
                </p>

                <button className="close-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default InfoModal;
