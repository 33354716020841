import {
    Commitment,
    Connection,
    PublicKey,
} from "@solana/web3.js";
import {Program,Provider} from "@coral-xyz/anchor";
import { Buffer } from "buffer/";
import {GlobalAccount} from "./globalAccount";
import {BondingCurveAccount} from "./bondingCurveAccount";
import {DEFAULT_COMMITMENT,} from "./util";
import {PumpFun, IDL} from "./IDL";
const PROGRAM_ID = "6EF8rrecthR5Dkzon8Nwu78hRvfCKubJ14M5uBEwF6P";
export const GLOBAL_ACCOUNT_SEED = "global";
export const BONDING_CURVE_SEED = "bonding-curve";

export class PumpFunSDK {
    public program: Program<PumpFun>;
    public connection: Connection;

    constructor(provider?: Provider) {
        this.program = new Program<PumpFun>(IDL as PumpFun, provider);
        this.connection = this.program.provider.connection;
        console.log("connection GOT!");

    }


    async getBondingCurveAccount(
        mint: PublicKey,
        commitment: Commitment = DEFAULT_COMMITMENT
    ) {
        const tokenAccount = await this.connection.getAccountInfo(
            this.getBondingCurvePDA(mint),
            commitment
        );
        if (!tokenAccount) {
            return null;
        }
        return BondingCurveAccount.fromBuffer(tokenAccount!.data);
    }

    async getGlobalAccount(commitment: Commitment = DEFAULT_COMMITMENT) {
        const [globalAccountPDA] = PublicKey.findProgramAddressSync(
            [Buffer.from(GLOBAL_ACCOUNT_SEED)],
            new PublicKey(PROGRAM_ID)
        );

        const tokenAccount = await this.connection.getAccountInfo(
            globalAccountPDA,
            commitment
        );

        return GlobalAccount.fromBuffer(tokenAccount!.data);
    }

    getBondingCurvePDA(mint: PublicKey) {
        return PublicKey.findProgramAddressSync(
            [Buffer.from(BONDING_CURVE_SEED), mint.toBuffer()],
            this.program.programId
        )[0];
    }

}
